import { DefaultTheme } from "styled-components";

export const lightTheme: DefaultTheme = {
  iconWithTitle: "var(--iconWithTitleLight)",
  navbarBackground: "var(--primaryBackgroundColorLight)",
  bodyBackground: "var(--secondaryBackgroundColorLight)",
  navColor: "var(--black)",
  titleColor: "var(--primaryColorLight)",
  textColor: "var(--black)",
  linearRightColor: "var(--linearRightColorLight)",
  linearLeftColor: "var(--linearLeftColorLight)",
  balackAndWhite: "var(--black)",
  modalBackground: "var(--modalLightBackground)",
  modalShadow: "var(--modalLightShadow)",
  modalCloseStroke: "var(--modalCloseStrokeLight)",
  labelColor: "var(--labelColorLight)",
  inputBackground: "var(--inputLightBackground)",
  buttonBackground: "var(--buttonBackgroundLight)",
  emailBackground: "var(--emailLightBackground)",
  emailBorder: "var(--emailLightBorder)",
  selectBackground: "var(--selectLightBackground)",
  starsColor: "var(--starsLightColor)",
  cardBackground: "var(--cardLightBackground)",
  iconsColorAndButtonBorder: "var(--iconsLightColorAndButtonBorder)",
  figmaBg: "var(--figmaLightBg)",
  firstBoxShadow: "var(--firstBoxShadowLight)",
  secondBoxShadow: "var(--secondBoxShadowLight)",
  tooltipFirstShdow: "var(--tooltipFirstShdowLight)",
  tooltipSecondShdow: "var(--tooltipSecondShdowLight)",
  errorColor: "var(--errorColorLight)",
  errorNumberColor: "var(--errorNumberColorLight)",
  postColor: "var(--postColorLight)",
  postAnchor: "var(--postAnchorLight)",
  postHr: "var(--postHrLight)",
  postQuoteColor: "var(--postQuoteColorLight)",
  postQuoteBorderColor: "var(--postQuoteBorderColorLight)",
  postTrBgColor: "var(--postTrBgColorLight)",
  postThBorderColor: "var(--postThBorderColorLight)",
  postSecTrBgColor: "var(--postSecTrBgColorLight)",
};
