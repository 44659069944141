import { DefaultTheme } from "styled-components";

export const darkTheme: DefaultTheme = {
  iconWithTitle: "var(--iconWithTitleDark)",
  navbarBackground: "var(--primaryBackgroundColorDark)",
  bodyBackground: "var(--secondaryBackgroundColorDark)",
  navColor: "var(--white)",
  titleColor: "var(--primaryColorDark)",
  textColor: "var(--primaryColorDark)",
  linearRightColor: "var(--linearRightColorDark)",
  linearLeftColor: "var(--linearLeftColorDark)",
  balackAndWhite: "var(--white)",
  modalBackground: "var(--modalDarkBackground)",
  modalShadow: "var(--modalDarkShadow)",
  modalCloseStroke: "var(--modalCloseStrokeDark)",
  labelColor: "var(--labelColorDark)",
  inputBackground: "var(--inputDarkBackground)",
  buttonBackground: "var(--buttonBackgroundDark)",
  emailBackground: "var(--emailDarkBackground)",
  emailBorder: "var(--emailDarkBorder)",
  selectBackground: "var(--selectDarkBackground)",
  starsColor: "var(--starsDarkColor)",
  cardBackground: "var(--cardDarkBackground)",
  iconsColorAndButtonBorder: "var(--iconsDarkColorAndButtonBorder)",
  figmaBg: "var(--figmaDarkBg)",
  firstBoxShadow: "var(--firstBoxShadowDark)",
  secondBoxShadow: "var(--secondBoxShadowDark)",
  tooltipFirstShdow: "var(--tooltipFirstShdowDark)",
  tooltipSecondShdow: "var(--tooltipSecondShdowDark)",
  errorColor: "var(--errorColorDark)",
  errorNumberColor: "var(--errorNumberColorDark)",
  postColor: "var(--postColorDark)",
  postAnchor: "var(--postAnchorDark)",
  postHr: "var(--postHrDark)",
  postQuoteColor: "var(--postQuoteColorDark)",
  postQuoteBorderColor: "var(--postQuoteBorderColorDark)",
  postTrBgColor: "var(--postTrBgColorDark)",
  postThBorderColor: "var(--postThBorderColorDark)",
  postSecTrBgColor: "var(--postSecTrBgColorDark)",
};
